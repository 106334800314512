<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-toolbar-title class="ml-2 mt-2">Filtros</v-toolbar-title>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8" xl="8" align="end">   
                <v-btn
                    class="ml-2 my-1"
                    color="primary"
                    @click="novo()"
                >
                    <v-icon left>mdi-plus</v-icon> Criar
                </v-btn>
            </v-col>
        </v-row>
        <v-card class="mt-2 py-4 px-2" elevation="1">
            <v-row no-gutters class="mb-4">
                <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                    <v-select
                        label="Tipo"
                        :items="tipos"
                        v-model="tipo"
                        dense 
                        outlined
                        hide-details
                    >
                    </v-select>
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4" align="end">
                </v-col>
            </v-row>
            <v-data-table
                :headers="headers"
                :items="dados"
                :items-per-page="15"
                :footer-props="{
                    'items-per-page-options': [5, 10, 15, 25, 50, 100]
                }"
                multi-sort
                class="elevation-0"
            >
                <template v-slot:[`item.botao`]="{ item }"> 
                    <v-icon
                        small
                        class="mr-2"
                        color="primary"
                        @click="removerConfirm(item)"
                        title="Remover"
                    >
                        mdi-trash-can
                    </v-icon>
                </template>
            </v-data-table>
        </v-card>

        <v-dialog
            v-model="dialogo"
            width="90%"
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    Filtro
                </v-card-title>
                <v-card-text class="mt-4"> 
                    <v-form ref="form"> 
                        <v-row no-gutters class="mt-4">
                            <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="px-2"> 
                                <v-select
                                    label="Tabela"
                                    :items="tabelas"
                                    v-model="obj.tabela"
                                    outlined
                                    dense 
                                    :rules="regra.obrigatorio"
                                >
                                </v-select> 
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="px-2"> 
                                <v-text-field
                                    outlined
                                    dense
                                    label="Ordem"
                                    v-model="obj.ordem"
                                    :rules="regra.obrigatorio"
                                ></v-text-field> 
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="px-2"> 
                                <v-text-field
                                    outlined
                                    dense 
                                    disabled
                                    label="Tipo"
                                    v-model="tipo"
                                ></v-text-field> 
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="dialogo = false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="salvar()"
                    >
                        <v-icon left>mdi-content-save</v-icon> Salvar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="confirm"
            width="600"
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    Remover Filtro
                </v-card-title>
                <v-card-text class="mt-4"> 
                    Deseja remover o filtro "{{obj.tabela}}"?
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="confirm=false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                    <v-btn
                        color="error"
                        class="pr-4"
                        @click="remover"
                    >
                        <v-icon left>mdi-close</v-icon> Remover
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-overlay :value="carregando" :z-index="300">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        
        <v-snackbar
            v-model="alerta"
            :color="alertaColor"
            :timeout="alertaTimeout"
            top
            style="z-index: 400"
        >
            <v-alert :type="alertaColor">
                {{ alertaTexto }}
            </v-alert>
            <v-btn
                dark
                text
                class="float-right"
                @click="alerta=false"
            >
            Fechar
            </v-btn>
        </v-snackbar>

    </v-container>
</template>

<script>

export default {
    data() {
        return {
            headers: [
                { text: 'Tabela', value: 'tabela', align: 'center'},
                { text: 'Nome', value: 'nome', align: 'center'},
                { text: 'Ordem', value: 'ordem', align: 'center'},
                { text: '', value: 'botao', align: 'center', sortable: false },
            ],
            dados: [], 
            objNovo: {
                tabela: null,
                ordem: null,
                tipo: null,
            },
            tipos: [
                {value:'acordo_listagem', text: 'Listagem de Acordos'},
                {value:'acordo_auditoria', text: 'Auditoria de Acordos'},
                {value:'gerencial_auditoria', text: 'Gerencial de Auditorias'},
                {value:'gerencial_planejado', text: 'Gerencial de Planejado / Investido'}
            ],
            obj: {},
            carregando: false, 
            tipo: 'acordo_listagem',
            busca: null, 
            dialogo: false,
            confirm: false,
            alerta: false,
            alertaTexto: '',
            alertaColor: 'error',
            alertaTimeout: 3000,
            regra: {
                obrigatorio: [
                    v => !!v || 'Campo obrigatório.',
                ],
            },
            tabelas: [],
        }
    },
    watch: {
        tipo() {

            this.filtrar()
        },
    },
    async created() {

        this.filtrar()
    },
    methods: {
        async filtrar() {

            this.carregando = true

            var data = {}

            data.tipo = this.tipo

            var response = await this.$http().post(`/filtro/lista`, {data: data})   

            this.dados = response.data.lista
            this.tabelas = response.data.tabelas

            this.carregando = false
        },
        novo() {

            this.obj = {...this.objNovo}

            this.obj.tipo = this.tipo

            try {
                this.$refs.form.resetValidation()
            } catch (error) {
                //console.log(error)
            }

            this.dialogo = true
        }, 
        async salvar() {

            for (const key in this.obj) {

                if (typeof this.obj[key] == 'string') {

                    this.obj[key] = this.obj[key].trim()
                }
            }

            if(!this.$refs.form.validate()) return

            this.carregando = true

            var data = this.obj

            var response = await this.$http().post(`/filtro/salvar`, {data: data})

            this.carregando = false

            if(response.data.sucesso){

                this.filtrar()
                this.dialogo = false
            }
            else{

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.msg
            }
        },
        removerConfirm(item) {

            this.obj = {...item}

            this.obj.tipo = this.tipo

            this.confirm = true
        },
        async remover() {

            this.carregando = true

            var data = this.obj

            var response = await this.$http().post(`/filtro/remover`, {data: data})

            this.carregando = false

            if(response.data.sucesso){

                await this.filtrar()

                this.confirm = false
            }
            else{

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.msg
            }
        }, 
    },
}
</script>