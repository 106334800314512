<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-toolbar-title class="ml-2 mt-2">Tabelas</v-toolbar-title>
            </v-col>
        </v-row>
        <v-card class="mt-2 py-4" elevation="1"> 
            <v-row no-gutters class="mb-4">
                <v-col cols="4" class="px-2">
                    <v-select
                        label="Tabela"
                        :items="lista.tabelas"
                        v-model="tabela"
                        outlined
                        dense 
                        hide-details
                    >
                    </v-select>
                </v-col>
                <v-col cols="4" class="px-2">
                    <v-switch
                        label="Ativo no menu"
                        v-model="obj.fn_menu"
                    ></v-switch>
                </v-col>
                <v-col cols="4" align="end" class="px-2"> 
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="salvar()"
                    >
                        <v-icon left>mdi-content-save</v-icon> Salvar Configurações
                    </v-btn>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4" class="px-2">
                    <v-text-field
                        outlined
                        dense 
                        label="Nome"
                        v-model="obj.nome"
                        :rules="regra.nome"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4" class="px-2">
                    <v-text-field
                        outlined
                        dense 
                        label="Plural"
                        v-model="obj.plural"
                        :rules="regra.plural"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4" class="px-2">
                    <v-text-field
                        outlined
                        dense 
                        label="Nome arquivo"
                        v-model="obj.arquivo"
                        :rules="regra.arquivo"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4" class="px-2" v-if="tabela == 'faturamento.faturamento'">
                    <v-select
                        label="Campo Produto / Marca"
                        :items="lista.camposProdutoMarca"
                        v-model="obj.produtoMarca"
                        outlined
                        dense 
                    >
                    </v-select>
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4" class="px-2" v-if="tabela == 'produto'">
                    <v-select
                        label="Associar Ações"
                        :items="['Sim', 'Não']"
                        v-model="obj.produtoAcao"
                        outlined
                        dense 
                    >
                    </v-select>
                </v-col>
            </v-row>
            <v-data-table
                :headers="headers"
                :items="dados"
                v-model="configuracoes"
                :items-per-page="100"
                hide-default-footer
                multi-sort
                class="elevation-0"
                show-select
                item-key="ad"
            >
                <template v-slot:[`item.ad`]="{ item }">
                    {{ item.ad }}
                    {{ ( ['ad_2','ad_3','ad_4'].includes(item.ad) && tabela == 'loja' ? ` (Aprovador recibo)` : `` ) }}
                    {{ ( ['ad_6','ad_7','ad_8','ad_9','ad_10'].includes(item.ad) && tabela == 'acordo' ? ` (Tipo data)` : `` ) }}
                </template>
                <template v-slot:[`item.nome`]="{ item }">
                    <v-text-field
                        dense 
                        hide-details
                        v-model="item.nome"
                    >
                    </v-text-field>
                </template>
                <template v-slot:[`item.obrigatorio`]="{ item }">
                    <v-simple-checkbox
                        v-model="item.obrigatorio"
                        :ripple="false"
                    >
                    </v-simple-checkbox>
                </template>
            </v-data-table>
            <v-row no-gutters class="mt-4">
                <v-col cols="12" align="end"> 
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="salvar()"
                    >
                        <v-icon left>mdi-content-save</v-icon> Salvar Configurações
                    </v-btn>
                </v-col>
            </v-row>
        </v-card>

        <v-overlay :value="carregando" :z-index="300">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>

        <v-snackbar
            v-model="alerta"
            :color="alertaColor"
            :timeout="alertaTimeout"
            top
            style="z-index: 400"
        >
            <v-alert :type="alertaColor">
                {{ alertaTexto }}
            </v-alert>
            <v-btn
                dark
                text
                class="float-right"
                @click="alerta=false"
            >
            Fechar
            </v-btn>
        </v-snackbar>

    </v-container>
</template>

<script>

export default {
    data() {
        return {
            headers: [
                { text: 'Tabela', value: 'tabela', align: 'center'},
                { text: 'Adicional', value: 'ad', align: 'center'},
                { text: 'Nome', value: 'nome', align: 'center'},
                { text: 'Obrigatório', value: 'obrigatorio', align: 'center'},
            ],
            dados: [],
            configuracoes: [],
            lista: {
                tabelas: [],
                camposProdutoMarca: [],
            },
            tabela: null,
            pagination: {},
            carregando: false,
            alerta: false,
            alertaTexto: '',
            alertaColor: 'error',
            alertaTimeout: 3000,
            obj: {
                nome: null,
                plural: null,
                arquivo: null,
                produtoMarca: 'marca_nome',
                produtoAcao: 'Não',
            },
            regra: { 
                nome: [v => !!v || 'Campo obrigatório.'],
                plural: [v => !!v || 'Campo obrigatório.'],
                arquivo: [
                    v => !!v || 'Campo obrigatório.',
                    v => /^[a-zA-Z0-9_]+$/.test(v) || 'O campo arquivo só pode ter letras e números sem acentos e caracteres especiais e o caracter underline (_).',
                ],
            },
        }
    },
    watch: {
        tabela() {
            this.filtrar()
        },
    },
    async created() {

        var data = {
            where: {fn_config: 1},
            colunas: `tabela || ' - ' || nome as text, tabela as value`,
        }

        this.lista.tabelas = ( await this.$http().post('/configuracao/tabelas', {data: data}) ).data
    },
    methods: {
        async filtrar() {

            this.carregando = true

            var data = {}

            data.tabela = this.tabela

            response = await this.$http().post(`/configuracao/tabelaDados`, {data: data})

            this.obj = response.data[0]

            var response = await this.$http().post('/configuracao/lista', {data: data})

            this.dados = response.data.lista

            this.lista.camposProdutoMarca = response.data.camposProdutoMarca

            if(this.tabela == 'faturamento.faturamento') {

                this.obj.produtoMarca = response.data.produtoMarca.length ? response.data.produtoMarca[0].ad : 'marca_nome'
            }

            this.configuracoes = []

            for (const d of this.dados) {

                if(d.ativo) {

                    this.configuracoes.push(d)
                }
            }

            this.carregando = false
        },
        async salvar() {

            this.alerta = false

            if(
                this.configuracoes.find(e => ['cnpj_matriz'].includes(e.ad)) &&
                this.configuracoes.find(e => ['matriz'].includes(e.ad))
            ) {

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = 'Se o campo cnpj_matriz estiver selecionado o campo matriz não pode estar selecionado.'
                return
            }

            if(
                this.configuracoes.find(e => ['cnpj_matriz'].includes(e.ad)) &&
                this.configuracoes.find(e => ['filial'].includes(e.ad))
            ) {

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = 'Se o campo cnpj_matriz estiver selecionado o campo filial não pode estar selecionado.'
                return
            }

            this.carregando = true

            var data = {}

            for (var i = this.dados.length - 1; i >= 0; i--) {

                let o = {...this.dados[i]}

                if(o.forca_obrigatorio && !this.configuracoes.find(e => e.ad == o.ad && e.obrigatorio)) {

                    let obj = {...this.dados.find(e => e.ad == o.ad)}

                    obj.obrigatorio = true

                    obj.nome = o.ad == 'nome' ? (this.lista.tabelas.find(e => e.value == this.tabela)).text : (o.ad.slice(0, 3) == 'id_' ? o.ad.charAt(3).toUpperCase() + o.ad.slice(4) : o.ad.charAt(0).toUpperCase() + o.ad.slice(1))

                    this.configuracoes.unshift(obj)
                }

                if(this.configuracoes.find(e => e.ad == o.ad && ['cnpj_matriz', 'matriz'].includes(o.ad))) {

                    this.configuracoes.find(e => e.ad == o.ad).obrigatorio = true
                }
            }

            data.configuracoes = this.configuracoes
            data.tabela = this.tabela
            data.obj = this.obj

            if(this.tabela == 'faturamento.faturamento') {

                data.obj.produtoMarcaNome = this.lista.camposProdutoMarca.find(e => e.value == data.obj.produtoMarca).nome
            }

            var response = await this.$http().post('/configuracao/salvar', {data: data})

            this.carregando = false

            if(!response.data.sucesso){

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.msg
            }

            this.filtrar()
        },
    },
}
</script>