<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-toolbar-title class="ml-2 mt-2">Permissões</v-toolbar-title>
            </v-col>            
            <v-col cols="12" sm="12" md="8" lg="8" xl="8" align="end"> 
                <v-btn
                    class="ml-2 my-1"
                    color="primary"                     
                    @click="exportar(false)"   
                >
                    <v-icon left>mdi-microsoft-excel</v-icon> Exportar                    
                </v-btn>
                <v-btn
                    class="ml-2 my-1"
                    color="primary"
                    @click="abrirImportar()"
                >
                    <v-icon left>mdi-upload</v-icon> Importar
                </v-btn> 
            </v-col>
        </v-row>
        <v-card class="mt-2 py-4 px-2" elevation="1"> 
            <v-row no-gutters>
                <v-col v-for="(obj, index) in tabelas" :key="'filtro'+index" cols="12" sm="12" md="4" lg="4" xl="4" class="px-2">
                    <v-autocomplete
                        :label="obj.nome"
                        :items="lista[obj.tabela]"
                        v-model="filtro[obj.var]"
                        multiple
                        clearable
                        outlined
                        dense 
                    >
                        <template v-slot:prepend-item>
                            <v-list-item
                            ripple
                            @mousedown.prevent
                            @click="selecionarTudo(obj.var, obj.tabela)"
                            >
                            <v-list-item-action>
                                <v-icon :color="filtro[obj.var].length > 0 ? 'indigo darken-4' : ''">
                                {{ iconeSelecionarTudo(obj.var, obj.tabela) }}
                                </v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>
                                Selecionar Tudo
                                </v-list-item-title>
                            </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                        </template>
                        <template v-slot:selection="{item, index}">
                            <div 
                                v-if="filtro[obj.var].length === 1 && index === 0"
                                style="width: 70% !important; white-space: nowrap !important; text-overflow: ellipsis !important; overflow: hidden;"
                            >{{ item.text }}</div>
                            <span
                                v-if="filtro[obj.var].length > 1 && index === 0"
                                class="grey--text caption mr-1"
                            >{{ filtro[obj.var].length }} selecionados</span>
                        </template>
                    </v-autocomplete>
                </v-col>
            </v-row>
            <v-row no-gutters class="mb-4">
                <v-col cols="12" sm="12" md="4" lg="4" xl="4" class="px-2">
                    <v-text-field
                        v-model="busca"
                        append-icon="mdi-magnify"
                        label="Pesquisar"
                        dense 
                        outlined
                        hide-details
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="8" lg="8" xl="8"  class="px-2" align="end">
                    <v-btn
                        class="ml-2 mb-1"
                        color="primary"
                        @click="limpar()"
                    >
                        <v-icon left>mdi-filter-off</v-icon> Limpar
                    </v-btn> 
                    <v-btn
                        class="ml-2 mb-1"
                        color="primary"
                        @click="filtrar()"
                    >
                        <v-icon left>mdi-filter</v-icon> Filtrar
                    </v-btn>
                    <v-btn
                        class="ml-2 mb-1"
                        color="primary"
                        @click="salvar()"
                    >
                        <v-icon left>mdi-content-save</v-icon> Salvar Permissões
                    </v-btn>
                </v-col>
            </v-row>
            <v-data-table
                :headers="headers"
                :items="dados"
                :items-per-page="5"
                :footer-props="{
                    'items-per-page-options': [5, 10, 15, 25, 50, 100]
                }"
                :options.sync="pagination"
                multi-sort
                class="elevation-0"
                show-select
                v-model="permissoes"
                item-key="linha"
                @toggle-select-all="selecionaTudoTabela($event)"
            >
            </v-data-table>
            <v-row no-gutters class="mt-4">
                <v-col cols="12" sm="12" md="8" lg="8" xl="8">
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4"  class="px-2" align="end">
                    <v-btn
                        class="ml-2 mb-1"
                        color="primary"
                        @click="salvar()"
                    >
                        <v-icon left>mdi-content-save</v-icon> Salvar Permissões
                    </v-btn>
                </v-col>
            </v-row>
        </v-card>

        <v-dialog
            v-model="dialogoImportar"
            width="90%"            
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    Importar CSV
                </v-card-title>
                <v-card-text>
                    <v-alert
                        class="mt-4"
                        text
                        type="info"
                        border="left"
                        style="font-size: 12px;"
                    >
                        <ul>
                            <li>
                                Os nomes das colunas devem ser <b>idênticos</b> aos da base exportada.
                            </li>
                            <li>
                                Coluna(s) obrigatória(s): <b>Status Permissão, Função e Setor</b>.
                            </li>
                            <li>
                                A junção das colunas <b>Função + Setor</b> é a chave para cadastro e atualizações.
                            </li>
                            <li>
                                Quando houver duplicidade de linha (<b>Função + Setor</b>) a última linha será considerada.
                            </li>
                            <li>
                                Na coluna <b>Status Permissão</b> coloque <b>1</b> para <b>ativar a permissão</b> e qualquer valor <b>diferente de 1</b> para <b>desativar a permissão</b>.
                            </li>
                        </ul>  
                    </v-alert> 
                    <v-row no-gutters>  
                        <v-col cols="8" class="px-2">
                            <v-form ref="form" v-model="isCsv">
                                <v-file-input 
                                    label="Arquivo CSV" 
                                    outlined 
                                    dense
                                    v-model="arquivoCSV"
                                    :rules="regra.csv"
                                    accept=".csv"
                                ></v-file-input>
                            </v-form>
                        </v-col> 
                        <v-col cols="4" class="px-2">
                            <v-btn
                                color="primary"
                                :disabled="!isCsv"
                                @click="importar()"
                            >
                                <v-icon left>mdi-upload</v-icon> 
                                Importar Arquivo
                            </v-btn>
                        </v-col> 
                    </v-row> 
                    <v-expand-transition>
                        <div v-show="erro">
                            <v-row no-gutters class="mb-0">  
                                <v-col cols="6">
                                    <download-csv
                                        :data="erros"
                                        :labels="errosLabels"
                                        name= "erros.csv"
                                        delimiter=";"
                                    >
                                        <v-btn color="primary">
                                            <v-icon left>mdi-microsoft-excel</v-icon>
                                            Exportar Logs
                                        </v-btn>
                                    </download-csv>
                                </v-col>
                            </v-row> 
                            <v-data-table
                                :headers="headerErros"
                                :items="erros"
                                :items-per-page="5"
                                :options.sync="paginationErros" 
                                class="elevation-0 data-table-2"
                            ></v-data-table>
                        </div>
                    </v-expand-transition>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="dialogoImportar=false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-overlay :value="carregando" :z-index="300">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        
        <v-snackbar
            v-model="alerta"
            :color="alertaColor"
            :timeout="alertaTimeout"
            top
            style="z-index: 400"
        >
            <v-alert :type="alertaColor">
                {{ alertaTexto }}
            </v-alert>
            <v-btn
                dark
                text
                class="float-right"
                @click="alerta=false"
            >
            Fechar
            </v-btn>
        </v-snackbar>

    </v-container>
</template>

<script>

export default {
    data() {
        return {
            headers: [
                { text: 'Área', value: 'pai', align: 'center'},
                { text: 'Função', value: 'menu', align: 'center'},
                { text: 'Setor', value: 'setor', align: 'center'},
                { text: 'ID Setor', value: 'id_setor', align: 'center'},
                { text: 'Status Setor', value: 'status_setor', align: 'center'},
                { text: 'Cargo', value: 'cargo', align: 'center'},
                { text: 'ID Cargo', value: 'id_cargo', align: 'center'},
                { text: 'Usuário', value: 'usuario', align: 'center'},
                { text: 'ID Usuário', value: 'id_usuario', align: 'center'},
                { text: 'Login', value: 'login', align: 'center'},
            ],
            dados: [], 
            permissoes: [],
            tabelas: [
                {nome: 'Área', tabela: 'pai', var: 'pai'},
                {nome: 'Função', tabela: 'menu', var: 'id_menu'},
                {nome: 'Cargo', tabela: 'cargo', var: 'id_cargo'},
                {nome: 'Usuário', tabela: 'usuario', var: 'id_usuario'},
                {nome: 'Setor', tabela: 'setor', var: 'id_setor'},
                {nome: 'Status Setor', tabela: 'ativo', var: 'ativo'},
                {nome: 'Status Permissão', tabela: 'permissao', var: 'permissao'},
            ],            
            lista: {
                pai: [],
                menu: [],
                menuTemp: [],
                setor: [],
                cargo: [],
                usuario: [],
                ativo: [{text:'Ativo', value: 1}, {text:'Desativo', value: 0}],
                permissao: [{text:'Selecionado', value: 1}, {text:'Não Selecionado', value: 0}],
            },
            filtro: {
                pai: [],
                id_menu: [],
                id_setor: [],
                id_cargo: [],
                id_usuario: [],
                ativo: [],
                permissao: [],
            },
            pagination: {}, 
            carregando: false, 
            busca: null, 
            alerta: false,
            alertaTexto: '',
            alertaColor: 'error',
            alertaTimeout: 3000,
            dialogoImportar: false,
            paginationErros: {}, 
            arquivoCSV: null,
            regra: { 
                csv: [
                    v => !!v || 'Selecione um arquivo.',
                    v => (!!v && [
                        '.csv', 
                        '.CSV'
                    ].includes(v.name.substr(-4))) || 'O arquivo deve ser do tipo CSV.',
                ] ,
            },  
            isCsv: false,
            headerErros: [
                { text: 'Descrição', value: 'msg', align: 'left'},
            ],
            erros: [],
            errosLabels: {msg: 'Descrição'}, 
            erro: false
        }
    },
    watch: {
        pai() {

            this.filtro.id_menu = []
            
            this.lista.menu = this.filtro.pai.length ? this.lista.menuTemp.filter(e => this.filtro.pai.includes(e.pai)).map(e => ({...e})) : this.lista.menuTemp.map(e => ({...e}))
        }
    },
    async created() { 

        var response = {}
        
        for (const t of this.tabelas) {

            this.filtro[t.var] = []

            if (['setor', 'cargo', 'usuario'].includes(t.tabela)) {

                response = await this.$http().post(`/${t.tabela}/filtro`, {data: {filtro: []}})

                this.lista[t.tabela] = response.data
            }
        }

        
        response = await this.$http().post('/usuario/menu', {}) 

        var menu = []
        var pai = []
        var paiNome = []

        for (const m of response.data) {

            if(m.pai == 0) {

                pai.push({text: m.nome, value: m.id_menu})

                paiNome[m.id_menu] = m.nome
            }
        }

        for (const m of response.data) {

            if(m.pai > 0) {

                menu.push({text: paiNome[m.pai] + ' - ' + m.nome, value: m.id_menu, pai: m.pai})
            }   
        }

        this.lista.pai = pai
        this.lista.menuTemp = menu.map(e => ({...e}))
        this.lista.menu = menu.map(e => ({...e}))

        //this.filtrar()
    },
    computed: {
        pai() {

            return this.filtro.pai
        },
    },
    methods: {
        limpar() {

            this.busca = null

            for (const t of this.tabelas) {

                this.filtro[t.var] = []
            }

            this.filtrar()
        },
        async filtrar() {

            this.carregando = true

            var data = {}

            data.filtro = {...this.filtro}
            data.busca = this.busca

            var response = await this.$http().post('/usuario/permissao', {data: data})   
            
            this.dados = response.data
            
            this.permissoes = []
            
            for (const d of response.data) {
                
                if(d.permissao) {

                    this.permissoes.push(d)
                } 
            }

            this.carregando = false
        },
        async exportar() {

            this.carregando = true

            var data = {}

            data.filtro = {...this.filtro}
            data.busca = this.busca

            var response = await this.$http().post('/usuario/permissaoExportar', {data: data}) 

            window.location = this.$http('baseURL')+response.data.url

            this.carregando = false
        },
        async salvar() {

            this.carregando = true
           
            var permissao = []
            var index = -1
            
            for (const [i, d] of this.dados.entries()) {

                if ( ! (i % 1000) ) {

                    index++
                    permissao[index] = []
                }

                permissao[index].push({
                    id_setor: d.id_setor,
                    id_menu: d.id_menu,
                    permissao: this.permissoes.includes(d)
                })
            }

            for (const p of permissao) {
           
                let data = {permissao: p}

                let response = await this.$http().post('/usuario/salvarPermissao', {data: data})
                
                if(!response.data.sucesso){
                    
                    this.alerta = true
                    this.alertaColor = 'error'
                    this.alertaTimeout = -1
                    this.alertaTexto = response.data.msg
                }
            }

            this.carregando = false
        },
        iconeSelecionarTudo(var_id, tabela) {

            if(this.filtro[var_id].length === this.lista[tabela].length) return 'mdi-close-box'
            if (this.filtro[var_id].length > 0 && !(this.filtro[var_id].length === this.lista[tabela].length)) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
        selecionarTudo (var_id, tabela) {

            this.carregando = true

            setTimeout(() => {

                if(this.filtro[var_id].length === this.lista[tabela].length) {

                    this.filtro[var_id] = []
                }
                else {

                    this.filtro[var_id] = this.lista[tabela].map(e => e.value)
                }
            }, 100)

            setTimeout(() => { this.carregando = false }, (this.lista[tabela].length > 100 ? this.lista[tabela].length : 110))
        },
        selecionaTudoTabela(event) {

            this.carregando = true

            this.permissoes = []

            if (event.value) {
            
                for (const d of this.dados) {
                    
                    this.permissoes.push(d)
                }
            }

            setTimeout(() => { this.carregando = false }, 1000)
        },
        abrirImportar() {

            this.arquivoCSV = null
            this.erro = false

            try {
                this.$refs.form.resetValidation()
            } catch (error) {
                //console.log(error)
            }  

            this.dialogoImportar = true 
        },
        async importar() {
            
            this.carregando = true
            
            var formData = new FormData()

            formData.append("files", this.arquivoCSV)

            this.arquivoCSV = null

            try {
                this.$refs.form.resetValidation()
            } catch (error) {
                //console.log(error)
            }  

            var response = await this.$http().post(`/usuario/importarPermissao`, formData)  
            
            this.carregando = false

            this.filtrar()

            if(response.data.sucesso){

                this.dialogoImportar = false
            }
            else{

                this.erros = response.data.erros
                this.pagination.page = 1
                this.erro = true
            }  
        }, 
    },
}
</script>