const servidor = require('./servidor')

import axios from 'axios'
import store from '@/store'

var baseURL = ''

if(servidor.node_env == 'development'){

    baseURL = 'http://localhost:3802/'
}

if(servidor.node_env == 'test'){

    baseURL = 'https://testapi.ipdvifinance.com.br/'
}

if(servidor.node_env == 'production'){

    baseURL = 'https://api.ipdvifinance.com.br/'
}

export const http = (tipo = 'http') => {

    var http = axios.create({
        baseURL: baseURL,
        params: {
            x: Date.now()
        },
        headers: {
            token: store.getters.getToken
        },
    })

    http.interceptors.response.use((response) => {

        return response
    }, 
    (error) => {

        if (error.response.status == 401) {
            store.dispatch('sessaoExpirou')
        }
    })

    if(tipo == 'http') {

        return http
    }
    if(tipo == 'baseURL') {

        return baseURL
    }
}