<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-toolbar-title class="ml-2 mt-2">{{titulo}}</v-toolbar-title>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8" xl="8" align="end">   
                <v-btn
                    class="ml-2 my-1"
                    color="primary"
                    @click="novo()"
                >
                    <v-icon left>mdi-plus</v-icon> Criar
                </v-btn>
            </v-col>
        </v-row>
        <v-card class="mt-2 py-4 px-2" elevation="1">  
            <v-row no-gutters class="mb-4">
                <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                    <v-select
                        label="Ativos"
                        :items="[{text:'Sim', value: 1},{text:'Não', value: 0},{text:'Todos', value: null}]"
                        v-model="ativo"
                        dense 
                        outlined
                        hide-details
                    >
                    </v-select>
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4" align="end">
                </v-col>
            </v-row>
            <v-data-table
                :headers="headers"
                :items="dados"
                :items-per-page="5"
                :footer-props="{
                    'items-per-page-options': [5, 10, 15, 25, 50, 100]
                }"
                :options.sync="pagination"
                multi-sort
                class="elevation-0"
            >
                <template v-slot:[`item.botao`]="{ item }"> 
                    <v-icon
                        small
                        color="primary"   
                        @click="editar(item)"
                        title="Editar"
                    >
                        mdi-pencil
                    </v-icon>
                </template>
                <template v-slot:[`item.validacao`]="{ item }"> 
                    {{item.ultimo_nivel ? item.validacao : ''}}
                </template>
            </v-data-table>
            <v-alert
                class="mt-4"
                text
                type="info"
                border="left"
                style="font-size: 12px;"
            >
                <ul>
                    <li>
                        O desdobramento deve ter pelo menos um atributo vinculado, caso contrário não irá funcionar a listagem no planejamento.
                    </li>
                    <li>
                        Os desdobramentos devem ter pelo menos um <b>Modelo de Acordo</b> configurado para funcionar corretamente (para o cálculo do investimento no planejamento).
                    </li>
                    <li>
                        O campo <b>Validação do Investimento</b> só é <b>relevante</b> para desdobramentos de <b>último nível</b>, porque somente nestes desdobramento é validado o investimento, nos demais níveis de desdobramento não é realizado a validação do investimento.
                    </li>
                    <li>
                        Desdobramentos de <b>primeiro nível</b> não possuem <b>remanejamento</b> e nem <b>regras de remanejamento</b> (pois não tem nível superior para solicitar verba).
                    </li>
                </ul>
            </v-alert>
        </v-card>

        <v-dialog
            v-model="dialogo"
            width="90%"
            scrollable
        >
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    {{tituloDialogo}}
                </v-card-title>
                <v-card-text class="mt-4">
                    <v-form ref="form"> 
                        <v-row no-gutters class="mt-4">
                            <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="px-2"> 
                                <v-text-field
                                    outlined
                                    dense 
                                    disabled
                                    :label="'ID '+tituloDialogo"
                                    v-model="obj['id_'+tabela]"
                                ></v-text-field> 
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="px-2"> 
                                <v-text-field
                                    outlined
                                    dense 
                                    :label="tituloDialogo"
                                    v-model="obj.nome"
                                    :rules="regra.nome"
                                ></v-text-field> 
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="px-2"> 
                                <v-select
                                    label="Tipo"
                                    :items="['Planejado', 'Faturamento']"
                                    v-model="obj.tipo" 
                                    :rules="regra.tipo" 
                                    outlined
                                    dense 
                                >
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="px-2"> 
                                <v-text-field
                                    outlined
                                    dense 
                                    label="Ordem"
                                    v-model="obj.ordem"
                                    :rules="regra.ordem"
                                    type="number"
                                ></v-text-field> 
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="px-2"> 
                                <v-select
                                    label="Desdobramento Anterior"
                                    :items="dadosTodos.filter(e => e.id_desdobramento != obj.id_desdobramento && e.ativo == 1).map(e => ({value: e.id_desdobramento, text: e.nome}))"
                                    v-model="obj.id_desdobramento_pai"
                                    :rules="regra.id_desdobramento_pai"
                                    clearable
                                    outlined
                                    dense 
                                >
                                </v-select> 
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="px-2"> 
                                <v-select
                                    label="Hierarquia"
                                    :items="hierarquias"
                                    v-model="obj.id_hierarquia"
                                    :rules="regra.id_hierarquia"
                                    clearable
                                    outlined
                                    dense 
                                >
                                </v-select> 
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="px-2"> 
                                <v-select
                                    label="Período"
                                    :items="['Anual', 'Mensal']"
                                    v-model="obj.periodo" 
                                    :rules="regra.periodo" 
                                    outlined
                                    dense 
                                >
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="px-2"> 
                                <v-select
                                    label="Validação do Investimento"
                                    :items="['Anual', 'Mensal']"
                                    v-model="obj.validacao" 
                                    :rules="regra.validacao" 
                                    outlined
                                    dense 
                                >
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="px-2">
                                <v-select
                                    label="Ativo"
                                    :items="[{text:'Sim', value: 1},{text:'Não', value: 0}]"
                                    v-model="obj.ativo"
                                    outlined
                                    dense 
                                >
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="px-2">
                                <v-textarea
                                    label="Query Where"
                                    v-model="obj.where_query"
                                    rows="3"
                                    outlined
                                    dense 
                                >
                                </v-textarea> 
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="mb-2">
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" align="end">
                                <v-btn
                                    color="primary"
                                    class="pr-4"
                                    @click="novaTabela()"
                                    v-show="obj.id_desdobramento"
                                >
                                    <v-icon left>mdi-plus</v-icon> Adicionar Atributo
                                </v-btn> 
                            </v-col>
                        </v-row>
                        <v-data-table
                            :headers="headerTabelas"
                            :items="tabelas"
                            :items-per-page="5"
                            :footer-props="{
                                'items-per-page-options': [5, 10, 15, 25, 50, 100]
                            }"
                            :options.sync="paginationTabelas" 
                            class="elevation-0"
                            v-show="obj.id_desdobramento"
                        >
                            <template v-slot:[`item.fn_remanejamento_regra`]="{ item }"> 
                                {{!!item.fn_remanejamento_regra}}
                            </template>
                            <template v-slot:[`item.botao`]="{ item }"> 
                                <v-icon
                                    small
                                    class="mr-2"
                                    color="primary"
                                    @click="removerConfirm(item, 'desdobramento_tabela')"
                                    title="Remover Atributo"
                                >
                                    mdi-trash-can
                                </v-icon>
                            </template>
                        </v-data-table>
                        <v-row no-gutters class="mb-2 mt-4">
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" align="end">
                                <v-btn
                                    color="primary"
                                    class="pr-4"
                                    @click="novaRegra()"
                                    v-show="obj.id_desdobramento && desdobramento.id_desdobramento_pai"
                                >
                                    <v-icon left>mdi-plus</v-icon> Adicionar Regra de Remanejamento
                                </v-btn> 
                            </v-col>
                        </v-row>
                        <v-data-table
                            :headers="remanejamento.headers"
                            :items="remanejamento.dados"
                            :items-per-page="5"
                            :footer-props="{
                                'items-per-page-options': [5, 10, 15, 25, 50, 100]
                            }"
                            :options.sync="remanejamento.pagination" 
                            class="elevation-0"
                            v-show="obj.id_desdobramento && desdobramento.id_desdobramento_pai"
                        >   
                            <template v-slot:[`item.botao`]="{ item }"> 
                                <v-icon
                                    small
                                    class="mr-2"
                                    color="primary"
                                    @click="removerConfirm(item, 'remanejamento_aprovacao')"
                                    title="Remover Regra de Remanejamento"
                                >
                                    mdi-trash-can
                                </v-icon>
                            </template>
                        </v-data-table>
                    </v-form>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="dialogo = false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="salvar()"
                    >
                        <v-icon left>mdi-content-save</v-icon> Salvar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="dialogoRemover"
            width="600"
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    {{dialogoRemoverTitulo}}
                </v-card-title>
                <v-card-text class="mt-4"> 
                    {{dialogoRemoverTexto}}
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="dialogoRemover=false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                    <v-btn
                        color="error"
                        class="pr-4"
                        @click="remover(dialogoRemoverTipo)"
                    >
                        <v-icon left>mdi-close</v-icon> Remover
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="dialogoTabela"
            width="600"
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    Adicionar Atributo
                </v-card-title>
                <v-card-text class="mt-4"> 
                    <v-form ref="formTabela">
                        <v-select
                            label="Atributo"
                            :items="tabelasDisponiveis"
                            v-model="desdobramento_tabela.tabela"
                            :rules="regra.tabela"
                            outlined
                            dense 
                        >
                        </v-select>
                        <v-select v-show="['setor','setor_2','setor_3','setor_4'].includes(desdobramento_tabela.tabela)"
                            label="Cargo"
                            :items="cargos"
                            v-model="desdobramento_tabela.id_cargo"
                            :rules="regra.id_cargo"
                            outlined
                            dense  
                        >
                        </v-select>
                        <v-checkbox
                            v-show="
                                //se o atributo não for de loja ou ja tiver um atributo fake de setor não pode ser atributo fake
                                ['setor','setor_2','setor_3','setor_4','regiao', 'cluster', 'bandeira', 'canal'].includes(desdobramento_tabela.tabela) &&
                                (
                                    tabelasDisponiveis.filter(e => ['setor','setor_2','setor_3','setor_4'].includes(e.value)).length == 4 || 
                                    ['regiao', 'cluster', 'bandeira', 'canal'].includes(desdobramento_tabela.tabela)
                                )
                            "
                            label="Somente Visualisação (Atributo Fake)"
                            v-model="desdobramento_tabela.fn_fake"
                            hide-details 
                        ></v-checkbox>
                        <v-checkbox
                            label="Regra de Remanejamento"
                            v-model="desdobramento_tabela.fn_remanejamento_regra"
                            hide-details 
                        ></v-checkbox>
                        <v-alert
                            class="mt-4"
                            text
                            type="info"
                            border="left"
                            style="font-size: 12px;"
                        >
                            <ul>
                                <li>
                                    O sistema só aceita atributos relacionados as lojas como atributos fakes.
                                </li>
                                <li>
                                    No sistema todo só pode haver um setor como atributo fake (e este setor deve ser o primeiro setor vinculado aos desdobramentos do sistema).
                                </li>
                                <li>
                                    O campo Regra de Remanejamento só deve ser marcado para atributos que forem usados nas regras de remanejamento.
                                </li>
                            </ul>
                        </v-alert>
                    </v-form>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="dialogoTabela=false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="adicionarTabela()"
                    >
                        <v-icon left>mdi-content-save</v-icon> Adicionar Atributo
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        
        <v-dialog
            v-model="remanejamento_aprovacao.dialogo"
            width="800"
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    Adicionar Regra
                </v-card-title>
                <v-card-text class="mt-4"> 
                    <v-form ref="formRegra">
                        <v-text-field
                            outlined
                            dense 
                            label="Nome"
                            v-model="remanejamento_aprovacao.nome"
                            :rules="regra.obrigatorio"
                        ></v-text-field>
                        <v-text-field
                            outlined
                            dense 
                            label="Status de Entrada"
                            v-model="remanejamento_aprovacao.status_entrada"
                            :rules="regra.obrigatorio"
                        ></v-text-field>
                        <v-text-field
                            outlined
                            dense 
                            label="Status de Saída"
                            v-model="remanejamento_aprovacao.status_saida"
                            :rules="regra.obrigatorio"
                        ></v-text-field>
                        <v-text-field
                            outlined
                            dense 
                            label="Ordem"
                            v-model="remanejamento_aprovacao.ordem"
                            :rules="regra.obrigatorio"
                        ></v-text-field>
                        <v-text-field
                            outlined
                            dense 
                            label="Regra"
                            v-model="remanejamento_aprovacao.regra"
                        ></v-text-field>
                        <v-alert
                            class="mt-4"
                            text
                            type="info"
                            border="left"
                            style="font-size: 12px;"
                        >
                            <ul>
                                <li>
                                    O campo <b>Nome</b> é utilizado na área de <b>Permissões</b> para dar permissão a regra de aprovação.
                                </li>
                                <li>
                                    O <b>Status de Entrada</b> da primeira regra verdadeira, será o status inicial na criação da solicitação de remanejamento.
                                </li>
                                <li>
                                    <b>Status de Entrada</b> que são iguais a algum <b>Status de Saida</b> são removidos automaticamente na criação da solicitação de remanejamento.
                                </li>
                                <li>
                                    Quando uma solicitação é aprovada ela tem seu status alterado para o <b>Status de Saída</b> da regra de aprovação.
                                </li>
                                <li>
                                    Para finalizar uma sequência de aprovação deve-se colocar o <b>Status de Saída</b> = <b>aprovado</b>.
                                </li>
                            </ul>
                        </v-alert>
                    </v-form>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="remanejamento_aprovacao.dialogo=false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="adicionarRegra()"
                    >
                        <v-icon left>mdi-content-save</v-icon> Adicionar Regra
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-overlay :value="carregando" :z-index="300">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        
        <v-snackbar
            v-model="alerta"
            :color="alertaColor"
            :timeout="alertaTimeout"
            top
            style="z-index: 400"
        >
            <v-alert :type="alertaColor">
                {{ alertaTexto }}
            </v-alert>
            <v-btn
                dark
                text
                class="float-right"
                @click="alerta=false"
            >
            Fechar
            </v-btn>
        </v-snackbar>  

    </v-container>
</template>

<script>

export default {
    data() {
        return {
            tabela: 'desdobramento',
            titulo: 'Desdobramentos',
            tituloDialogo: 'Desdobramento',
            headers: [
                { text: 'ID Desdobramento', value: 'id_desdobramento', align: 'center', sortable: false },
                { text: 'Desdobramento', value: 'nome', align: 'center', sortable: false },
                { text: 'Tipo', value: 'tipo', align: 'center', sortable: false },
                { text: 'Desdobramento Anterior', value: 'desdobramento_pai', align: 'center', sortable: false },
                { text: 'Ordem', value: 'ordem', align: 'center', sortable: false },
                { text: 'Hierarquia', value: 'hierarquia', align: 'center', sortable: false },
                { text: 'Período', value: 'periodo', align: 'center', sortable: false },
                { text: 'Validação do Investimento', value: 'validacao', align: 'center', sortable: false },
                { text: '', value: 'botao', align: 'center', sortable: false },
            ],
            dados: [], 
            dadosTodos: [], 
            objNovo: {
                id_desdobramento: null,
                nome: null,
                ativo: 1,
                id_desdobramento_pai: null,
                ordem: null,
                id_hierarquia: null,
                periodo: null,
                validacao: null,
                where_query: null,
                tipo: null,
            },
            obj: {},
            desdobramento: {},
            pagination: {},
            paginationTabelas: {},
            carregando: false,
            ativo: 1,
            dialogo: false,
            alerta: false,
            alertaTexto: '',
            alertaColor: 'error',
            alertaTimeout: 3000,
            arquivoCSV: null,
            regra: { 
                nome: [v => !!v || 'Campo obrigatório.'],
                tipo: [v => !!v || 'Campo obrigatório.'],
                id_desdobramento_pai: [v => this.obj.tipo == 'Planejado' || this.obj.tipo == 'Faturamento' && !v || 'Desdobramento do tipo faturamento não pode ter desdobramento anterior.'],
                tabela: [v => !!v || 'Campo obrigatório.'],
                periodo: [
                    (v) => {
                        
                        if(!v) {

                            return 'Campo obrigatório.'
                        }
                        
                        let obj = this.obj

                        if(obj.tipo == 'Faturamento' && obj.periodo != 'Mensal') {

                            return 'O desdobramento é do tipo faturamento, então o periodo deve ser mensal.'
                        }

                        if(!obj.id_desdobramento_pai) {

                            return true
                        }

                        let periodo_pai = this.dadosTodos.find(e => e.id_desdobramento == obj.id_desdobramento_pai).periodo

                        if(periodo_pai == 'Mensal' && obj.periodo != 'Mensal') {

                            return 'O período do desdobramento anterior é mensal, então o periodo deve ser mensal.'
                        }

                        return true
                    } ,
                ],
                validacao: [v => !!v || 'Campo obrigatório.'],
                ordem: [v => !!v || 'Campo obrigatório.'],
                obrigatorio: [v => !!v || 'Campo obrigatório.'],
                id_cargo: [v => this.desdobramento_tabela.tabela != 'setor' || !!v || 'Campo obrigatório.'],
            }, 
            hierarquias: [],
            dialogoRemover: false,
            dialogoRemoverTitulo: null,
            dialogoRemoverTexto: null,
            dialogoRemoverTipo: null,
            headerTabelas: [
                { text: 'Atributo', value: 'nome', align: 'center', sortable: false },
                { text: 'Ordem', value: 'ordem', align: 'center', sortable: false },
                { text: 'Cargo', value: 'cargo', align: 'center', sortable: false },
                { text: 'Somente Visualisação', value: 'fn_fake', align: 'center', sortable: false },
                { text: 'Regra Remanejamento', value: 'fn_remanejamento_regra', align: 'center', sortable: false },
                { text: '', value: 'botao', align: 'center', sortable: false },
            ],
            tabelas: [],
            desdobramento_tabela: {
                id_desdobramento: null,
                tabela: null,
                id_cargo: null,
                fn_fake: false,
                fn_remanejamento_regra: false,
            },
            dialogoTabela: false,
            tabelas_config: [],
            tabelasDisponiveis: [],
            cargos: [],
            remanejamento: {
                headers: [
                    { text: 'ID', value: 'id_aprovacao', align: 'center', sortable: false },
                    { text: 'Nome', value: 'nome', align: 'center', sortable: false },
                    { text: 'Status de Entrada', value: 'status_entrada', align: 'center', sortable: false },
                    { text: 'Status de Saída', value: 'status_saida', align: 'center', sortable: false },
                    { text: 'Ordem', value: 'ordem', align: 'center', sortable: false },
                    { text: 'Regra', value: 'regra', align: 'center', sortable: false },
                    { text: '', value: 'botao', align: 'center', sortable: false },
                ],
                dados: [],
            },
            remanejamento_aprovacao: {
                nome: null,
                status_entrada: null,
                status_saida: null,
                ordem: null,
                regra: null,
                dialogo: false,
            },
        }
    },
    watch: {
        ativo() {

            this.filtrar()
        },
    },
    async created() {
        
        var data = {
            where: {fn_atributo: 1, tabela: ['!=', 'loja']},
            colunas: 'nome as text, tabela as value',
        }
        
        this.tabelas_config = ( await this.$http().post('/configuracao/tabelas', {data: data}) ).data

        this.hierarquias = ( await this.$http().post(`/hierarquia/filtro`, {}) ).data

        this.filtrar()
    },
    methods: {
        async filtrar() {

            this.carregando = true

            var data = {}
            data.filtro = {}
            data.filtro.ativo = this.ativo == null ? [] : [this.ativo]

            var response = await this.$http().post(`/${this.tabela}/lista`, {data: data})
            
            this.dados = response.data

            data.filtro.ativo = []

            response = await this.$http().post(`/${this.tabela}/lista`, {data: data})
            
            this.dadosTodos = response.data
            
            this.carregando = false
        },
        novo() {
           
            this.obj = {...this.objNovo}

            try {
                this.$refs.form.resetValidation()
            } catch (error) {
                //console.log(error)
            }  

            this.dialogo = true
        }, 
        editar(item) {
           
            this.obj = {...item}

            try {
                this.$refs.form.resetValidation()
            } catch (error) {
                //console.log(error)
            }  

            this.dialogo = true
            
            this.filtrarTabelas(this.obj)
        }, 
        async salvar() {

            for (const key in this.obj) {

                if (typeof this.obj[key] == 'string') {

                    this.obj[key] = this.obj[key].trim()
                }
            }
            
            if(!this.$refs.form.validate()) return
            
            this.carregando = true
            
            var data = this.obj

            var response = await this.$http().post(`/${this.tabela}/salvar`, {data: data})   
            
            this.carregando = false

            if(response.data.sucesso){

                this.filtrar()
                this.dialogo = false
            }
            else{

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.msg
            }  
        },  
        async removerConfirm(item, tipo) {

            if(tipo == 'desdobramento_tabela') {
                
                this.dialogoRemoverTitulo = 'Remover Atributo'
                this.dialogoRemoverTexto = `Deseja realmente remover o atributo '${item.nome}'?`
                this.dialogoRemoverTipo = tipo

                this.desdobramento_tabela = {...item}
            }

            if(tipo == 'remanejamento_aprovacao') {
                
                this.dialogoRemoverTitulo = 'Remover Regra'
                this.dialogoRemoverTexto = `Deseja realmente remover a regra '${item.nome}'?`
                this.dialogoRemoverTipo = tipo

                this.remanejamento_aprovacao = {...item}
            }

            this.dialogoRemover = true
        }, 
        async remover(tipo) {
            
            this.carregando = true

            var data = {
                tipo: tipo
            }

            if(tipo == 'desdobramento_tabela') {
                
                data.id_desdobramento = this.desdobramento_tabela.id_desdobramento
                data.tabela = this.desdobramento_tabela.tabela
            }

            if(tipo == 'remanejamento_aprovacao') {
                
                data.id_aprovacao = this.remanejamento_aprovacao.id_aprovacao
            }

            var response = await this.$http().post(`/${this.tabela}/remover`, {data: data})  
            
            this.carregando = false

            if(response.data.sucesso){

                this.alerta = true
                this.alertaColor = 'success'
                this.alertaTimeout = 1000
                this.alertaTexto = response.data.msg 
                
                this.dialogoRemover = false
            }
            else{

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.msg
            } 

            if(tipo == 'desdobramento_tabela') {
                
                this.filtrarTabelas(this.obj)
            }
            if(tipo == 'remanejamento_aprovacao') {
                
                this.filtrarTabelas(this.obj)
            }
        },
        async filtrarTabelas(item) {
            
            this.carregando = true

            var data = {id_desdobramento: item.id_desdobramento}

            var response = await this.$http().post(`/${this.tabela}/tabelas`, {data: data})  

            this.tabelas = response.data.tabelas

            this.remanejamento.dados = response.data.remanejamentos

            response = await this.$http().post(`/planejamento/desdobramento`, {data: data})

            this.desdobramento = response.data

            this.cargos = ( await this.$http().post(`/hierarquia/hierarquiaCargo`, {data: {id_hierarquia: this.desdobramento.id_hierarquia}}) ).data

            this.cargos = this.cargos.map(e => ({text: e.cargo, value: e.id_cargo}))

            response = await this.$http().post(`/desdobramento/atributos`, {data: {desdobramento: this.desdobramento}})

            let atributos = response.data.map(e => e.tabela)

            this.tabelasDisponiveis = this.tabelas_config.filter(t => !atributos.includes(t.value))

            for (const t of this.tabelas) {

                t.nome = (this.tabelas_config.find(e => e.value == t.tabela)).text
            }
           
            this.carregando = false
        },
        novaTabela() {
           
            this.desdobramento_tabela = {
                id_desdobramento: this.obj.id_desdobramento,
                tabela: null,
                id_cargo: null,
                fn_fake: false,
                fn_remanejamento_regra: false,
            }

            try {
                this.$refs.formTabela.resetValidation()
            } catch (error) {
                //console.log(error)
            }  

            this.dialogoTabela = true
        },
        async adicionarTabela() {
            
            if(!this.$refs.formTabela.validate()) return
            
            this.carregando = true

            var data = this.desdobramento_tabela

            //se o atributo não for de loja ou ja tiver um atributo fake de setor não pode ser atributo fake
            if (
                !['setor','setor_2','setor_3','setor_4','regiao', 'cluster', 'bandeira', 'canal'].includes(this.desdobramento_tabela.tabela) ||
                (
                    ['setor','setor_2','setor_3','setor_4'].includes(this.desdobramento_tabela.tabela) &&
                    this.tabelasDisponiveis.filter(e => ['setor','setor_2','setor_3','setor_4'].includes(e.value)).length < 4
                )
            ) {

                data.fn_fake = false
            }

            data.fn_remanejamento_regra = data.fn_remanejamento_regra ? 1 : 0

            var response = await this.$http().post(`/${this.tabela}/adicionarTabela`, {data: data})  
            
            this.carregando = false

            if(response.data.sucesso){

                this.alerta = true
                this.alertaColor = 'success'
                this.alertaTimeout = 1000
                this.alertaTexto = response.data.msg 

                this.dialogoTabela = false
            }
            else{

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.msg
            } 

            this.filtrarTabelas(this.obj)
        },
        novaRegra() {

            this.remanejamento_aprovacao = {
                id_desdobramento: this.obj.id_desdobramento,
                nome: null,
                status_entrada: null,
                status_saida: null,
                ordem: null,
                regra: null,
                dialogo: true,
            }

            try {
                this.$refs.formRegra.resetValidation()
            } catch (error) {
                //console.log(error)
            }
        },
        async adicionarRegra() {
            
            if(!this.$refs.formRegra.validate()) return
            
            this.carregando = true

            var data = this.remanejamento_aprovacao

            var response = await this.$http().post(`/${this.tabela}/adicionarRegra`, {data: data})  
            
            this.carregando = false

            if(response.data.sucesso){

                this.remanejamento_aprovacao.dialogo = false
                this.filtrarTabelas(this.obj)
            }
            else{

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.msg
            } 
        },
    },
}
</script>