<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-toolbar-title class="ml-2 mt-2">Justificativas</v-toolbar-title>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8" xl="8" align="end">   
                <v-btn
                    class="ml-2 my-1"
                    color="primary"
                    @click="novo()"   
                >
                    <v-icon left>mdi-plus</v-icon> Criar
                </v-btn>
            </v-col>
        </v-row>
        <v-card class="mt-2 py-4 px-2" elevation="1">
            <v-row no-gutters class="mb-4">
                <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                    <v-select
                        label="Área"
                        :items="areas"
                        v-model="area"
                        dense 
                        outlined
                        hide-details
                    >
                    </v-select>
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4" align="end">
                    <v-text-field
                        v-model="busca"
                        append-icon="mdi-magnify"
                        label="Pesquisar"
                        dense 
                        outlined
                        hide-details
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-data-table
                :headers="headers"
                :items="dados"
                :items-per-page="5"
                :footer-props="{
                    'items-per-page-options': [5, 10, 15, 25, 50, 100]
                }"
                :options.sync="pagination"
                multi-sort
                class="elevation-0"
            >
                <template v-slot:[`item.botao`]="{ item }"> 
                    <v-icon
                        small
                        class="mr-2"
                        color="primary"
                        @click="editar(item)"
                        title="Editar"
                    >
                        mdi-pencil
                    </v-icon>
                    <v-icon
                        small
                        class="ml-4 mr-2"
                        color="primary"
                        @click="remover(item)"
                        title="Excluir"
                    >
                        mdi-delete
                    </v-icon>
                </template>
            </v-data-table>
        </v-card>

        <v-dialog
            v-model="dialogo"
            width="90%"
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    Justificativa
                </v-card-title>
                <v-card-text class="mt-4"> 
                    <v-form ref="form"> 
                        <v-row no-gutters class="mt-4">
                            <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="px-2"> 
                                <v-text-field
                                    outlined
                                    dense 
                                    disabled
                                    label="ID Justificativa"
                                    v-model="obj.id_justificativa"
                                ></v-text-field> 
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="px-2"> 
                                <v-text-field
                                    outlined
                                    dense 
                                    label="Justificativa"
                                    v-model="obj.justificativa"
                                    :rules="regra.obrigatorio"
                                ></v-text-field> 
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="px-2"> 
                                <v-select
                                    label="Área"
                                    :items="areas"
                                    v-model="obj.area"
                                    :rules="regra.obrigatorio"
                                    outlined
                                    dense
                                >
                                </v-select>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="dialogo = false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="salvar()"
                    >
                        <v-icon left>mdi-content-save</v-icon> Salvar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-overlay :value="carregando" :z-index="300">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        
        <v-snackbar
            v-model="alerta"
            :color="alertaColor"
            :timeout="alertaTimeout"
            top
            style="z-index: 400"
        >
            <v-alert :type="alertaColor">
                {{ alertaTexto }}
            </v-alert>
            <v-btn
                dark
                text
                class="float-right"
                @click="alerta=false"
            >
            Fechar
            </v-btn>
        </v-snackbar>

    </v-container>
</template>

<script>

export default {
    data() {
        return {
            headers: [
                { text: 'ID Justificativa', value: 'id_justificativa', align: 'center'},
                { text: 'Justificativa', value: 'justificativa', align: 'center'},
                { text: 'Área', value: 'area', align: 'center'},
                { text: '', value: 'botao', align: 'center', sortable: false },
            ],
            dados: [], 
            obj: {
                id_justificativa: null,
                justificativa: null,
                area: null,
            },
            areas: [
                { text: 'Auditoria APP IPDV 360', value: 'ifinance_auditoria'},
                { text: 'Checkout APP IPDV 360', value: 'ifinance_checkout'},
                { text: 'Reprovação de Remanejamento', value: 'reprovar_remanejamento'},
            ],
            area: 'ifinance_auditoria',
            pagination: {},
            carregando: false,
            busca: null,
            dialogo: false,
            alerta: false,
            alertaTexto: '',
            alertaColor: 'error',
            alertaTimeout: 3000,
            regra: {
                obrigatorio: [
                    v => !!v || 'Campo obrigatório.',
                ],
            },
        }
    },
    watch: {
        busca(v) {

            setTimeout(() => {

                if(v == this.busca){
                    
                    this.filtrar()
                }

            }, 1000)
        },
        area() {

            this.filtrar()
        },
    },
    async created() {

        this.filtrar()
    },
    methods: {
        async filtrar() {

            this.carregando = true

            var data = {}

            data.busca = this.busca

            data.filtro = {}
            data.filtro.area = [this.area]

            var response = await this.$http().post(`/justificativa/lista`, {data: data})   
            
            this.dados = response.data

            this.carregando = false
        },
        novo() {
           
            this.obj = {
                id_justificativa: null,
                justificativa: null,
                area: null,
            }

            try {
                this.$refs.form.resetValidation()
            } catch (error) {
                //console.log(error)
            }

            this.dialogo = true
        }, 
        editar(item) {
           
            this.obj = {...item}

            try {
                this.$refs.form.resetValidation()
            } catch (error) {
                //console.log(error)
            }  
            
            this.dialogo = true
        }, 
        async salvar() {

            if(!this.$refs.form.validate()) return

            this.carregando = true

            var data = this.obj

            var response = await this.$http().post(`/justificativa/salvar`, {data: data})   
            
            this.carregando = false

            if(response.data.sucesso){

                this.filtrar()
                this.dialogo = false
            }
            else{

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.msg
            }  
        },
        async remover(item) {

            this.carregando = true

            var data = item

            var response = await this.$http().post(`/justificativa/remover`, {data: data})   
            
            this.carregando = false

            if(response.data.sucesso){

                this.filtrar()
                this.dialogo = false
            }
            else{

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.msg
            }  
        },
    },
}
</script>