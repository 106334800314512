<template>
    <v-footer
        :color="colorfooter"
        :order="order"
        :height="getHeight"
        :app="app"
        :class="{
            'customShadow myCustomFooterClean': true,
            'rounded-16': !app,
        }"
        :style="{
            'margin-left': marginLeft,
            'margin-right': marginRight,
            'margin-bottom': marginBottom,
            'margin-top': marginTop,
            width: `calc((100% - ${marginLeft}) - ${marginRight}) !important`,
        }"
        :elevation="elevation"
    >
        <v-row justify="center" no-gutters>
            <v-col class="text-caption align-center d-flex" cols="1">
                <v-img
                    src="../../assets/images/login/logo_footer.png"
                    max-width="35.3"
                    max-height="15.9"
                    min-width="35.3"
                    min-height="15.9"
                />
            </v-col>
            <v-col
                :class="{
                    'text-caption align-center text-center': true,
                    'd-flex': !this.$vuetify.breakpoint.xs,
                }"
                :cols="this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm ? 11 : 9"
                :style="{
                    'font-size': getFontSize + ' !important',
                }"
            >
                &copy; iPDV
                {{ new Date().getFullYear() }} | All Rights Reserved
            </v-col>
            <v-col cols="1" v-show="!this.$vuetify.breakpoint.xs && !this.$vuetify.breakpoint.sm" class="">
                <v-btn
                    variant="text"
                    @click="goToLink('https://www.linkedin.com/company/ipdvsistemas/')"
                >
                    <v-icon>mdi-linkedin</v-icon>
                </v-btn>
            </v-col>
            <v-col cols="1" v-show="!this.$vuetify.breakpoint.xs && !this.$vuetify.breakpoint.sm" class="">
                <v-btn
                    variant="text"
                    @click="
                        goToLink(
                            'https://www.youtube.com/@ipdvinteligenciaestrategic2979'
                        )
                    "
                >
                    <v-icon>mdi-youtube</v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </v-footer>
</template>

<script>

export default {
    data() {
        return {
            

        }
    },
    props: {
        colorfooter: String,
        order: [Number, String],
        marginLeft: {
            type: String,
            default: "0px",
        },
        marginRight: {
            type: String,
            default: "0px",
        },
        marginBottom: {
            type: String,
            default: "0px",
        },
        marginTop: {
            type: String,
            default: "0px",
        },
        app: {
            type: Boolean,
            default: false,
        },
        clean: {
            type: Boolean,
            default: false,
        },
        elevation: {
            type: Number,
            default: 0,
        },
    },
    computed: {
        getHeight() {
            switch (this.$vuetify.breakpoint.name) {
                case "xs": return null;
                default: return 50;
            }
        },
        getFontSize() {
            switch (this.$vuetify.breakpoint.name) {
                case "xs": return "8px";
                default: return "12px";
            }
        },
    },
    methods: {
        goToLink(url) {
            window.open(url, "_blank");
        }
    },
}

</script>

<style>
.myCustomFooterClean {
    line-height: 20px;
    float: left !important;
    color: rgb(148, 151, 167) !important;
}
.v-footer {
    background: rgb(255, 255, 255) !important;
}
.rounded-16 {
    border-radius: 16px !important;
}
.myCustomFooterClean .theme--light.v-btn {
    color: rgb(148, 151, 167) !important;
}
.myCustomFooterClean .theme--light.v-btn.v-btn--has-bg {
    background-color: transparent;
}
.v-btn--is-elevated {
    box-shadow: none !important;
}
</style>
