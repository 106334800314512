<template>
    <v-app style="background: #fff">
        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        <link href="https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap" rel="stylesheet">
        <v-main>

            <v-container class="containerLogin">
                <v-row class="conteudo ipdvColorGradient" no-gutters>

                    <Slider />

                    <v-col xs="12" sm="12" md="5" lg="5" xl="5">
                        <v-card
                            color="transparent"
                            :class="{
                                'rounded-16': screen.xs || screen.sm,
                                'rounded-e-16': !screen.xs && !screen.sm,
                                'pa-32': screen.xs,
                                'pa-64': !screen.xs,
                            }"
                            class="flat"
                            height="100%"
                        >
                            <div
                                style="min-height: 90px"
                                :class="{
                                    'w-100 justify-center align-center d-flex':
                                    screen.xs || screen.sm,
                                    'd-none': !screen.xs && !screen.sm,
                                }"
                            >
                                <v-img
                                    src="https://s3.amazonaws.com/prateleira.ipdvonline.com.br/img/logo_default_header_branco.png"
                                    max-width="113px"
                                    max-height="51px"
                                />
                            </div>

                            <v-card
                                color="#fff"
                                class="rounded-12 justify-center d-flex flex-column align-center flat"
                                :height="screen.xs || screen.sm ? '80%' : '100%'"
                            >
                                <v-card
                                    color="transparent"
                                    class="rounded-0 pa-24 flat"
                                    max-width="349px"
                                    min-width="200px"
                                    width="100%"
                                >
                                    <v-row>
                                        <v-col 
                                            cols="7"
                                            md="8"
                                            lg="8"
                                            xl="8"
                                            xxl="8"
                                            :class="{
                                                'custom-text-h3': !screen.xs && !screen.sm,
                                                'custom-text-h5': screen.xs || screen.sm,
                                                'pa-b-32 pr-1': true,
                                            }"
                                            style="margin-top: 15px;"
                                        >
                                            Bem-vindo
                                        </v-col>
                                        <v-col
                                            cols="5"
                                            md="4"
                                            lg="4"
                                            xl="4"
                                            xxl="4"
                                            class="pt-2 pl-1"
                                        >
                                            <v-img src="@/assets/images/login/logo.png" height="80" contain></v-img>
                                        </v-col>
                                        <div>
                                            Cliente não encontrado. Verifique se o endereço do sistema está escrito corretamente.
                                        </div>
                                    </v-row>
                                </v-card>
                            </v-card>
                        </v-card>
                    </v-col>
                </v-row>

                <v-row no-gutters="">
                    <Footer
                        :app="false"
                        clean
                        colorfooter="header"
                        marginTop="4px"
                    />
                </v-row>
            </v-container>  
        </v-main>    
    </v-app>
</template>

<script>
import Slider from '../components/containment/Slider.vue'
import Footer from "../components/containment/Footer.vue";

export default {
    data() {
        return {
            screen: this.$vuetify.breakpoint,
        }
    },
    created() {

        this.$vuetify.theme.dark = false

        if (sessionStorage.getItem('cliente')) {

            this.$router.push({name: 'login', params: { cliente: sessionStorage.getItem('cliente') }})
            return
        }

        if (localStorage.getItem('cliente')) {

            this.$router.push({name: 'login', params: { cliente: localStorage.getItem('cliente') }})
            return
        }
        
        document.title = 'GVC - Cliente não encontrado.'
    },
    computed: {
    },
    methods: {
    },
    components: {
        Slider,
        Footer,
    },
}
</script>

<style scoped>
    * {
        scrollbar-width: thin;
        scrollbar-color: 
            color-mix(in srgb, rgb(53,89,145), #ffffff 45%) rgb(255,255,255);
    }
    html {
        overflow: scroll;
    }
    body {
        font-family: Ubuntu !important;
        font-weight: 400;
        line-height: 22px;
        font-size: 15px;
    }
    button, input, optgroup, select, textarea {
        font: inherit;
    }
    .v-application {
        font-family: Ubuntu !important;
        background-color: rgb(246, 246, 250) !important;
    }

    .v-btn.v-size--default.btn-acessar {
        font-size: 14px !important;
    }

    .conteudo {
        height: calc((100% - 0px) - 54px) !important;
    }
    .pa-24 {
        padding: 24px;
    }
    .pa-32 {
        padding: 32px;
    }

    .pa-b-32 {
        padding-bottom: 32px !important;
    }


    .pa-64 {
        padding: 64px;
    }
    .containerLogin {
        height: 100%;
        width: 100%;
        max-width: 1440px;
        padding: 32px;
    }

    .ipdvColorGradient {
        background: linear-gradient(
            to right,
            #053594 50%,
            #400597,
            #400597
        ) !important;
        border-radius: 16px !important;
    }

    .flat {
        box-shadow: none !important;
    }

    .rounded-12 {
        border-radius: 12px !important;
    }

    .rounded-16 {
        border-radius: 16px !important;
    }
    .rounded-e-16 {
        border-radius: 0 16px 16px 0 !important;
    }

    .btn-acessar {
        letter-spacing: 1.4px;
        font-weight: 500;
    }

    .btn-acessar > span  {
        font-size: 14px !important;
        letter-spacing: 1px !important;
    }
    
    .borda {
        border-radius: 14px !important;
    }
    .bordaForm {
        border-radius: 12px !important;
    }
    .bordaForm >>> input {
        border-radius: 12px !important;
        max-height: 40px;
        max-width: 130%;
        margin: 0px -12px;
    }
    .bordaBotao {
        padding: 0px 24px;
    }
    .bordaBotao button {
        border-radius: 12px !important;
        height: 41px !important;
    }
    .custom-text-h3 {
        font-weight: 500 !important;
        line-height: 36px !important;
        font-size: 26px !important;
    }
    .custom-text-h5 {
        font-weight: 500 !important;
        line-height: 24px !important;
        font-size: 18px !important;
    }
    .custom-text-ps {
        font-weight: 400 !important;
        line-height: 20px !important;
        font-size: 13px !important;
        max-height: 24px;
        letter-spacing: 1.5px;
        margin-top: -2px;
        color: #121212 !important;
    }
    .login-footer {
        margin-bottom: 15px;
    }
    .row + .row {
        margin-top: 0px !important;
    }
    @media (min-width: 1904px) {
        .col-xl-12 {
            flex: inherit !important;
        }
        .card-flex {
            flex: 0 0 100% !important;
        }
    }

    .v-text-field >>> input {
        padding: 10px 16px !important;
    }
    .v-text-field >>> label {
        padding-left: 4px !important;
    }
    .v-text-field--outlined >>> fieldset {
        z-index: 1000;
    }
    .autofill-fix >>> label {
        transform: translateY(-16px) scale(.75) !important;
    }
    .autofill-fix >>> input {
        font: unset !important;
    }
    .autofill-fix >>> fieldset {
        padding-left: 8px !important;
    }
    .autofill-fix >>> fieldset legend {
        width: 37px !important;
    }

    .link{
        color: #8c8c8c;
        text-decoration: underline;
        font-size: 14px;
        font-family: Arial, Helvetica, "Liberation Sans", Roboto, sans-serif;
        line-height: 1.5;
    }
    .ubuntu-light {
        font-family: "Ubuntu", sans-serif;
        font-weight: 300;
        font-style: normal;
    }

    .ubuntu-regular {
        font-family: "Ubuntu", sans-serif;
        font-weight: 400;
        font-style: normal;
    }

    .ubuntu-medium {
        font-family: "Ubuntu", sans-serif;
        font-weight: 500;
        font-style: normal;
    }

    .ubuntu-bold {
        font-family: "Ubuntu", sans-serif;
        font-weight: 700;
        font-style: normal;
    }

    .ubuntu-light-italic {
        font-family: "Ubuntu", sans-serif;
        font-weight: 300;
        font-style: italic;
    }

    .ubuntu-regular-italic {
        font-family: "Ubuntu", sans-serif;
        font-weight: 400;
        font-style: italic;
    }

    .ubuntu-medium-italic {
        font-family: "Ubuntu", sans-serif;
        font-weight: 500;
        font-style: italic;
    }

    .ubuntu-bold-italic {
        font-family: "Ubuntu", sans-serif;
        font-weight: 700;
        font-style: italic;
    }

    .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset {
        color: transparent !important;
    }
</style>