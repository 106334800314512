<template>
    <v-container fluid>

        <v-row no-gutters>
            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-toolbar-title class="ml-2 mt-2">Auditoria de Acordos</v-toolbar-title>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8" xl="8" align="end">
            </v-col>
        </v-row>

        <v-card class="mt-2 py-4" elevation="1">
            <v-row no-gutters>
                <v-col cols="12" class="ml-4">
                    <span 
                        @click="mostrarOcultar('filtros')"
                        style="cursor: pointer; font-weight: bold;"
                    >
                        {{mostrar.filtros.nome}}
                        <v-icon
                            class="ml-2 mr-4"
                            color="primary"
                        >
                            {{mostrar.filtros.icone}}
                        </v-icon>
                    </span>
                </v-col>
            </v-row>
            <v-expand-transition>
                <v-form ref="formFiltro" v-show="mostrar.filtros.flag">
                    <v-row no-gutters class="mt-2">
                        <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
                            <v-text-field
                                label="Período Inicial"
                                v-model="filtro.mesInicial"
                                :rules="regra.mesInicial"
                                required
                                outlined
                                dense 
                                type="month"
                                :style="$vuetify.theme.dark ? `color-scheme: dark;` : ``"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
                            <v-text-field
                                label="Período Final"
                                v-model="filtro.mesFinal"
                                :rules="regra.mesFinal"
                                required
                                outlined
                                dense 
                                type="month"
                                :style="$vuetify.theme.dark ? `color-scheme: dark;` : ``"
                            ></v-text-field>
                        </v-col> 
                        <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
                            <v-text-field
                                label="Acordo"
                                outlined
                                dense 
                                v-model="filtro.id_acordo"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
                            <v-text-field
                                label="Contrato"
                                outlined
                                dense 
                                v-model="filtro.id_contrato"
                            ></v-text-field>
                        </v-col>
                        <v-col v-for="(obj, index) in lista.filtro" :key="index" cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
                            <v-autocomplete
                                :label="obj.nome"
                                :items="lista[obj.tabela]"
                                v-model="filtro[obj.var_id]"
                                multiple
                                clearable
                                outlined
                                dense 
                            >
                                <template v-slot:prepend-item>
                                    <v-list-item
                                    ripple
                                    @mousedown.prevent
                                    @click="selecionarTudo(obj.var_id, obj.tabela, filtro, lista)"
                                    >
                                    <v-list-item-action>
                                        <v-icon :color="filtro[obj.var_id].length > 0 ? 'indigo darken-4' : ''">
                                        {{ iconeSelecionarTudo(obj.var_id, obj.tabela, filtro, lista) }}
                                        </v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                        Selecionar Tudo
                                        </v-list-item-title>
                                    </v-list-item-content>
                                    </v-list-item>
                                    <v-divider class="mt-2"></v-divider>
                                </template>
                                <template v-slot:selection="{item, index}">
                                    <div 
                                        v-if="filtro[obj.var_id].length === 1 && index === 0"
                                        style="width: 70% !important; white-space: nowrap !important; text-overflow: ellipsis !important; overflow: hidden;"
                                    >{{ item.text }}</div>
                                    <span
                                        v-if="filtro[obj.var_id].length > 1 && index === 0"
                                        class="grey--text caption mr-1"
                                    >{{ filtro[obj.var_id].length }} selecionados</span>
                                </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6" md="5" lg="3" xl="3" class="px-2 ml-auto" align="end">
                            <v-btn
                                class="ml-2 mb-1"
                                color="primary"
                                @click="limpar()"
                            >
                                <v-icon left>mdi-filter-off</v-icon> Limpar
                            </v-btn> 
                            <v-btn
                                class="ml-2 mb-1"
                                color="primary"
                                @click="filtrar()"
                            >
                                <v-icon left>mdi-filter</v-icon> Filtrar
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-expand-transition>
        </v-card>

        <v-card class="mt-2 py-4" elevation="1">
            <v-row no-gutters>
                <v-col cols="12" class="ml-4">
                    <span 
                        @click="mostrarOcultar('lista')"
                        style="cursor: pointer; font-weight: bold;"
                    >
                        {{mostrar.lista.nome}}
                        <v-icon
                            class="ml-2 mr-4"
                            color="primary"
                        >
                            {{mostrar.lista.icone}}
                        </v-icon>
                    </span>
                </v-col>
            </v-row>
            <v-expand-transition>
                <div>
                    <v-data-table
                        v-show="mostrar.lista.flag"
                        :headers="headers"
                        :items="dados"
                        :server-items-length="total" 
                        :items-per-page="5"
                        :footer-props="{
                            'items-per-page-options': [5, 10, 15, 25, 50, 100]
                        }"
                        :options.sync="pagination"
                        multi-sort
                        class="elevation-0 mx-2"
                        show-select
                        item-key="chave"
                        v-model="auditoria.acoes"
                    >
                        <template v-slot:[`item.percentual_executado`]="{ item }">
                            <div>
                                {{item.percentual_executado + '%'}}
                            </div>
                        </template>
                        <template v-slot:[`item.menu`]="{ item }">
                            <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        class="mr-2"
                                        color="primary"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        mdi-menu
                                    </v-icon>
                                </template>
                                <v-card>
                                    <v-list dense nav>
                                        <v-list-item 
                                            link 
                                            @click="auditar(item)"
                                        >
                                            <v-list-item-icon>
                                                <v-icon color="primary">mdi-image-check</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>Auditar Ação</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item 
                                            v-if="item.editar_investimento"
                                            link 
                                            @click="editar(item)"
                                        >
                                            <v-list-item-icon>
                                                <v-icon color="primary">mdi-pencil</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>Editar Investimento</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item 
                                            v-if="item.executado!='Pendente'"
                                            link 
                                            @click="buscaAuditoriasHistorico(item)"
                                        >
                                            <v-list-item-icon>
                                                <v-icon color="primary">mdi-file-document-outline</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>Histórico</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item
                                            link 
                                            @click="buscaRecibo(item)"
                                        >
                                            <v-list-item-icon>
                                                <v-icon color="primary">mdi-receipt-text</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>Recibo</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-card>
                            </v-menu>
                        </template>
                    </v-data-table>
                    <v-row no-gutters>
                        <v-col cols="12" class="px-2 mt-5" align="end">
                            <v-btn
                                class="ml-2 mb-1"
                                :disabled="auditoria.acoes.length==0"
                                color="primary"
                                @click="auditarSelecionados()"
                            >
                                <v-icon left>mdi-image-check</v-icon> Auditar ações selecionadas
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>
            </v-expand-transition>
        </v-card>

        <v-dialog
            v-model="auditoria.dialogo"
            width="90%"
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    {{auditoria.titulo}}
                </v-card-title>
                <v-card-text class="mt-4"> 
                    <v-form ref="formAuditar">
                        <v-row no-gutters>
                            <v-col cols="12" class="mb-2">
                                <b>O acordo foi executado?</b>
                            </v-col>
                            <v-col cols="12"> 
                                <v-select 
                                    label="Executado" 
                                    v-model="auditoria.obj.executado"
                                    :items="[{value: 1, text: 'Sim'}, {value: 0, text: 'Não'}]"  
                                    v-on:change="carregando=true; carregando=false;"
                                    :rules="regra.executado"
                                    outlined 
                                    dense
                                ></v-select>
                            </v-col>
                            <v-col cols="12" class="mb-2" v-if="auditoria.obj.executado==1">
                                <b>Selecione os arquivos das evidências.</b>
                            </v-col>
                            <v-col cols="12" v-if="auditoria.obj.executado==1"> 
                                <v-file-input 
                                    label="Evidências" 
                                    v-model="auditoria.obj.evidencia"
                                    :rules="regra.evidencia"
                                    outlined 
                                    dense
                                    small-chips
                                    counter
                                    multiple
                                    show-size
                                    clearable
                                    @change="changeFile"
                                    @click:clear="clearFile"
                                >
                                <template v-slot:selection="{ text, index }">
                                    <v-chip small close @click:close="removeFile(index)">
                                        {{ text }}
                                    </v-chip>
                                </template>
                                </v-file-input>
                            </v-col>
                            <v-col cols="12" class="mb-2" v-if="auditoria.obj.executado==0">
                                <b>No caso da não execução informe uma justificativa.</b>
                            </v-col>
                            <v-col cols="12" v-if="auditoria.obj.executado==0"> 
                                <v-select
                                    label="Justificativa"
                                    v-model="auditoria.obj.justificativa"
                                    :items="auditoria.justificativas"
                                    :rules="regra.justificativa"
                                    outlined
                                    dense 
                                ></v-select>
                            </v-col>
                            <v-col cols="12"> 
                                <v-textarea
                                    label="Observação"
                                    v-model="auditoria.obj.observacao"
                                    rows="2"
                                    outlined
                                    dense 
                                ></v-textarea> 
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="auditoria.dialogo=false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="auditarAcao"
                    >
                        <v-icon left>mdi-content-save</v-icon> Salvar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="investimento.dialogo"
            width="90%"
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    {{investimento.titulo}}
                </v-card-title>
                <v-card-text class="mt-4">
                    <v-data-table
                        :headers="investimento.headers"
                        :items="investimento.investimento"
                        :items-per-page="5"
                        :options.sync="investimento.pagination" 
                        class="elevation-1 data-table-2"
                    >
                        <template v-slot:[`item.investimento`]="{ item }">
                            <vuetify-money 
                                :ripple="false" 
                                v-model="item.investimento"
                                dense 
                                hide-details
                                backgroundColor=""
                                class="input_tabela"
                            />
                        </template>
                    </v-data-table>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="investimento.dialogo=false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="editarInvestimento"
                    >
                        <v-icon left>mdi-content-save</v-icon> Salvar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        
        <v-dialog
            v-model="auditoriasHistorico.dialogo"
            width="80%"
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    {{auditoriasHistorico.titulo}}
                </v-card-title>
                <v-data-table
                    :headers="auditoriasHistorico.headers"
                    :items="auditoriasHistorico.dados"
                    item-key="id_historico"
                    :items-per-page="5"
                    class="elevation-1 data-table-2"
                >
                    <template v-slot:[`item.evidencia`]="{ item }"> 
                        <div
                            v-if="item.evidencia"
                            @click="mostraEvidencia(item)"
                            title="Evidências"
                            style="cursor: pointer;"
                        >
                            <v-badge
                                bordered
                                overlap
                                color="primary"
                                :content="item.evidencia.length"
                            >
                                <v-icon
                                    small
                                    class="mr-2"
                                    color="primary"
                                >
                                    mdi-tooltip-image
                                </v-icon>
                            </v-badge>
                        </div>
                    </template>
                </v-data-table>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        @click="auditoriasHistorico.dialogo = false"
                    >
                        ok
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="evidencias.dialogo"
            width="70%"
            scrollable
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    {{evidencias.titulo}}
                </v-card-title>
                <v-card-text class="mt-4">
                    <v-list>
                        <v-list-item-group>
                            <v-list-item v-for="(item, i) in evidencias.lista" :key="i" class="pa-0" @click="mostraEvidenciaItem(item)">
                                <v-list-item-action class="ma-2">
                                    <v-card class="text-subtitle-2 d-flex align-center justify-center ma-0" width="80" height="40" rounded="1" elevation="0" color="secondary lighten-5">
                                        {{item.extensao}}
                                    </v-card>
                                </v-list-item-action>
                                <v-list-item-content class="text-caption ml-2">
                                    <v-list-item-title v-text="item.nome" :title="item.nome"></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        @click="evidencias.dialogo = false"
                    >
                        ok
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="evidencia.dialogo"
            width="70%"
            scrollable
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    {{evidencia.titulo}}
                </v-card-title>
                <v-card-text class="mt-4" v-html="evidencia.html">
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        @click="evidencia.dialogo = false"
                    >
                        ok
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-overlay :value="carregando" :z-index="300">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>

        <v-snackbar
            v-model="alerta"
            :color="alertaColor"
            :timeout="alertaTimeout"
            top
            style="z-index: 400"
        >
            <v-alert :type="alertaColor">
                {{ alertaTexto }}
            </v-alert>
            <v-btn
                dark
                text
                class="float-right"
                @click="alerta=false"
            >
            Fechar
            </v-btn>
        </v-snackbar>

    </v-container>
</template>

<script>

export default {
    data() {
        return {
            meses: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
            modelos: [],
            modelosLista: [],
            modelo: {
                passos: [],
                tabelas: [],
                mes_limite: 0,
                passoFilial: {
                    fnLoja: false,
                }
            },
            mostrar: {
                filtros: {flag: true, icone: 'mdi-chevron-up', nome: 'Filtros'},
                lista: {flag: true, icone: 'mdi-chevron-up', nome: 'Listagem'},
            },
            headers: [
                { text: 'Menu', value: 'menu', sortable: false, align: 'center'},
                { text: 'Tipo de Acordo', value: 'modelo', align: 'center'},
                { text: 'Contrato', value: 'id_contrato', align: 'center'},
                { text: 'Acordo', value: 'id_acordo', align: 'center'},
                { text: 'Mês', value: 'mes_nome', align: 'center'},
                { text: 'Parcela', value: 'parcela', align: 'center'},
                { text: 'Cliente', value: 'bandeira', align: 'center'},
                { text: 'Investimento', value: 'investimento_moeda', align: 'center'},
                { text: '% Executado', value: 'percentual_executado', align: 'center'},
                { text: 'Ação', value: 'acao', align: 'center'},
                { text: 'Auditoria', value: 'executado', align: 'center'},
                { text: 'Justificativa', value: 'justificativa', align: 'center'},
                { text: 'Observação', value: 'observacao_auditoria', align: 'center'},
            ],
            dados: [],
            lista: {},
            filtro: {},
            regra: {
                mesInicial: [
                    v => !!v || 'Campo obrigatório.',
                ],
                mesFinal: [
                    v => !!v || 'Campo obrigatório.',
                    v => (!!v && v >= this.filtro.mesInicial) || 'O período final deve ser igual ou posterior ao período inicial',
                ],
                obrigatorio: [v => !!v || 'Campo obrigatório.'],
                justificativa: [v => !!v || 'Campo obrigatório.'],
                executado: [v => !!v || v === 0 || 'Campo obrigatório.'],
                evidencia: [
                    v => v.length > 0 || 'Selecione pelo menos um arquivo.',
                    v => v.length > 0 && v.reduce((a, v) => a + v.size, 0) < 25*1000*1000 || 'O tamanho total dos arquivos não pode supeara 25 MB.',
                ],
            },
            total: null,
            paginationLimpa: {
                groupBy: [],
                groupDesc: [],
                itemsPerPage: 5,
                multiSort: true,
                mustSort: false,
                page: 1,
                sortBy: [],
                sortDesc: [],
            },
            pagination: {},
            carregando: false,
            alerta: false,
            alertaTexto: '',
            alertaColor: 'error',
            alertaTimeout: 3000,
            auditoria: {
                dialogo: false,
                titulo: null,
                justificativas: [],
                acoes: [],
                objNovo: {
                    acoes: [],
                    executado: null,
                    evidencia: [],
                    evidenciaTemp: [],
                    justificativa: null,
                    observacao: null,
                },
                obj: {},
            }, 
            investimento: {
                dialogo: false,
                titulo: null,
                headers: [
                    { text: 'Investimento', value: 'investimento', sortable: false, align: 'center', width: "160px", fixed: true},
                ],
                id_acordo: null,
                investimento: [],
                pagination: {}, 
            },
            auditoriasHistorico: {
                dialogo: false,
                titulo: null,
                headers: [
                    { text: 'Ação', value: 'acao', sortable: false, align: 'center'},
                    //{ text: 'CNPJ Filial', value: 'cnpj', sortable: false, align: 'center'},
                    //{ text: 'Razão Social', value: 'razao_social', sortable: false, align: 'center'},
                    { text: 'Data', value: 'data', sortable: false, align: 'center'},
                    { text: 'Responsável', value: 'usuario', sortable: false, align: 'center'},
                    { text: 'Auditoria', value: 'executado_nome', sortable: false, align: 'center'},
                    { text: 'Evidências', value: 'evidencia', sortable: false, align: 'center'},
                    { text: 'Justificativa', value: 'justificativa', sortable: false, align: 'center'},
                    { text: 'Observação', value: 'observacao', sortable: false, align: 'center'},
                ],
                dados: [],
            },
            evidencias: {
                dialogo: false,
                titulo: null,
                lista: [],
            },
            evidencia: {
                dialogo: false,
                titulo: null,
                html: null,
            },
        }
    },
    watch: {
        pagination: {
            handler () {
                if(this.modelosLista.length > 0) this.filtrar()
            },
            deep: true,
        }
    },
    async created() {

        this.carregando = true

        this.filtro.mesInicial = this.mesAtual
        this.filtro.mesFinal = this.mesAtual
        
        var data = {id_cargo: this.$store.getters.getCargo.id_cargo}

        var retorno = await this.$http().post(`/acordo/aditoria`, {data: data})
        
        this.modelosLista = retorno.data.modelosLista

        var marcaNome = null
        var produtoNome = null

        for (const m of this.modelosLista) {

            let tabelaMarca = m.tabelas.find(e => e.tabela == 'marca')
            if (tabelaMarca) marcaNome = tabelaMarca.nome

            let tabelaProduto = m.tabelas.find(e => e.tabela == 'produto')
            if (tabelaProduto) produtoNome = tabelaProduto.nome
        }
        
        if (produtoNome) this.investimento.headers.unshift({ text: produtoNome, value: 'produto', sortable: false, align: 'center'})
        if (marcaNome) this.investimento.headers.unshift({ text: marcaNome, value: 'marca', sortable: false, align: 'center'})

        this.lista = retorno.data.lista

        this.lista.filtro.push({tabela: 'executado', var_id: 'executado', nome: 'Auditoria'})

        this.lista.executado = [
            { text: 'Pendente', value: -1},
            { text: 'Não Executada', value: 0},
            { text: 'Reprovada', value: 2},
        ]

        for (const t of this.lista.filtro) {

            this.filtro[t.var_id] = []
        }

        this.auditoria.justificativas = retorno.data.justificativas.map(e => e.justificativa)

        this.carregando = false

        this.filtrar()
    },
    computed: { 
        mesAtual() {
            
            return this.$moment().format('YYYY-MM')
        },
    },
    methods: {
        mostrarOcultar(tipo) {

            this.mostrar[tipo].flag = !this.mostrar[tipo].flag
            this.mostrar[tipo].icone = this.mostrar[tipo].flag ? 'mdi-chevron-up' : 'mdi-chevron-down'
        },
        async filtrar() {

            if (this.modelosLista.length == 0) return

            if (!this.$refs.formFiltro.validate()) return

            this.carregando = true

            var data = {id_cargo: this.$store.getters.getCargo.id_cargo}

            data.pagination = this.pagination

            data.filtro = {...this.filtro}
            data.filtro.id_acordo = this.filtro.id_acordo ? [this.filtro.id_acordo] : []
            data.filtro.id_contrato = this.filtro.id_contrato ? [this.filtro.id_contrato] : []

            data.modelos = this.modelosLista

            var response = await this.$http().post(`/acordo/auditoriasAcao`, {data: data})

            this.dados = response.data.lista
            this.total = response.data.lista.length ? response.data.lista[0].total*1 : 0

            if(this.total == 0 && this.pagination.page > 1) {

                this.pagination.page = 1
                this.filtrar()
            }

            //remove linhas ja auditadas da linhas selecionadas
            this.auditoria.acoes = this.auditoria.acoes.filter(e => this.dados.map(d => d.chave).includes(e.id_acordo+'_'+e.id_acao))

            this.carregando = false
        },
        limpar() {

            this.pagination = {...this.paginationLimpa}

            this.filtro = {
                mesInicial: this.mesAtual,
                mesFinal: this.mesAtual,
                acordo: null,
                contrato: null,
            }  
            
            for (const t of this.lista.filtro) {
                
                this.filtro[t.var_id] = []
            }
        },
        auditar(item) {

            this.auditoria.obj = {...this.auditoria.objNovo}

            this.auditoria.obj.acoes = [{id_acordo: item.id_acordo, id_acao: item.id_acao}]

            try {
                this.$refs.formAuditar.resetValidation()
            } catch (error) {
                //console.log(error)
            } 

            this.auditoria.titulo = `Auditar Ação - (Acordo: ${item.id_acordo} / Ação: ${item.id_acao} - ${item.acao})`
            this.auditoria.dialogo = true
        },
        auditarSelecionados() {

            this.auditoria.obj = {...this.auditoria.objNovo}

            this.auditoria.obj.acoes = this.auditoria.acoes.map(e => ({id_acordo: e.id_acordo, id_acao: e.id_acao}))

            this.auditoria.titulo = `Auditar Ações Selecionadas`
            this.auditoria.dialogo = true
        },
        async auditarAcao () {
            
            if (!this.$refs.formAuditar.validate()) return
           
            this.carregando = true
            
            var data = this.auditoria.obj

            var formData = new FormData()

            for (const file of data.evidencia) {

                formData.append("files", file)
            }

            formData.append("data", JSON.stringify(data))

            var response = await this.$http().post('/acordo/auditarAcao', formData) 
            
            this.carregando = false
            
            if(response.data.sucesso) {

                this.auditoria.dialogo = false

                this.filtrar()
            }
            else{

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.msg
            }
        },
        editar (item) {

            this.investimento.investimento = [...item.produtos]

            this.investimento.id_acordo = item.id_acordo
            this.investimento.id_acao = item.id_acao
            this.investimento.acao = item.acao
            this.investimento.modelo = this.modelosLista.find(e => e.id_modelo == item.id_modelo)
            
            this.investimento.titulo = `Editar Investimento - (Acordo: ${item.id_acordo} / Ação: ${item.id_acao} - ${item.acao})`
            this.investimento.dialogo = true
        },
        async editarInvestimento () {
           
            //this.carregando = true
            
            var data = this.investimento

            var response = await this.$http().post('/acordo/editarInvestimento', {data: data}) 
            
            this.carregando = false
            
            if(response.data.sucesso) {
                
                this.investimento.dialogo = false

                this.filtrar()
            }
            else{

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.msg
            }
        },
        async buscaAuditoriasHistorico(item) {

            this.carregando = true
            
            this.auditoriasHistorico.dados = []
            
            this.auditoriasHistorico.titulo = `Auditorias Histórico - (Acordo: ${item.id_acordo} / Ação: ${item.id_acao} - ${item.acao})`

            var response = await this.$http().post('/acordo/auditoriasHistorico', {data: {id_auditoria: item.id_auditoria}})

            this.auditoriasHistorico.dados = response.data

            this.auditoriasHistorico.dialogo = true
            this.carregando = false
        },
        mostraEvidencia(item) {

            this.evidencias.titulo = `Evidências (Acordo: ${item.id_acordo}${item.id_acao ? ' / Ação: '+item.acao : ''}${item.cnpj ? ' / Loja: '+item.cnpj : ''})`
            this.evidencias.lista = item.evidencia
            this.evidencias.dialogo = true
        },
        mostraEvidenciaItem(item) {

            this.evidencia.titulo = `Evidência: ${item.nome}`

            if (item.tipo == 'application/pdf')  {

                this.evidencia.html = `<embed src="${item.evidencia}" type="application/pdf" width="100%" height="450px">`
                this.evidencia.dialogo = true
            }
            else if (item.tipo == 'text/plain')  {

                this.evidencia.html = `<embed src="${item.evidencia}" type="text/plain" width="100%" height="450px">`
                this.evidencia.dialogo = true
            }
            else if (item.tipo == 'application/json')  {

                this.evidencia.html = `<embed src="${item.evidencia}" type="application/json" width="100%" height="450px">`
                this.evidencia.dialogo = true
            }
            else if (item.tipo.split('/')[0] == 'image') {

                this.evidencia.html = `<img src="${item.evidencia}" width="100%"></img>`
                this.evidencia.dialogo = true
            }
            else {

                window.location = item.evidencia
            }
        },
        async buscaRecibo(item) {

            this.carregando = true
            
            var response = await this.$http().post('/acordo/recibo', {data: item})
            
            this.evidencia.dialogo = true
            this.evidencia.titulo = 'Recibo (Acordo: '+item.id_acordo+')'

            this.evidencia.html = `<embed src="${this.$http('baseURL')+response.data.url}?x=${Date.now()}" type="application/pdf" width="100%" height="450px">`

            this.carregando = false
        },
        iconeSelecionarTudo(var_id, tabela, filtro, lista) {

            if(!filtro[var_id] || !lista[tabela]) return

            if(filtro[var_id].length === lista[tabela].length) return 'mdi-close-box'
            if (filtro[var_id].length > 0 && !(filtro[var_id].length === lista[tabela].length)) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
        selecionarTudo (var_id, tabela, filtro, lista) {

            if(!filtro[var_id] || !lista[tabela]) return

            this.carregando = true

            setTimeout(() => {

                if(filtro[var_id].length === lista[tabela].length) {

                    filtro[var_id] = []
                }
                else {

                    filtro[var_id] = lista[tabela].map(e => e.value)
                }
            }, 100)

            setTimeout(() => { this.carregando = false }, (lista[tabela].length > 100 ? lista[tabela].length : 110))
        },
        changeFile () {

            for (const t of this.auditoria.obj.evidenciaTemp) {

                if ( !this.auditoria.obj.evidencia.map(e => e.name).includes(t.name) ) {

                    this.auditoria.obj.evidencia.push(t)
                }
            }

            this.auditoria.obj.evidenciaTemp = this.auditoria.obj.evidencia
        },
        clearFile () {

            this.auditoria.obj.evidencia = []
            this.auditoria.obj.evidenciaTemp = []
        },
        removeFile (index) {

            this.auditoria.obj.evidencia.splice(index, 1)
        }
    },
}
</script>

<style>
.style-investimento-negativo {
    color: var(--v-error-base) !important;
}
</style>